// example importing other Bootstrap Javascript Module
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Collapse from 'bootstrap/js/dist/collapse'
import AOS from 'aos';

function initAos() {
    AOS.init({
        duration: 600,
        once: true,
    });
}

function checkNavbarScrolled() {
    const navbarPrimaryEl = document.querySelector('.custom-navbar')

    if (navbarPrimaryEl) {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                navbarPrimaryEl.classList.add('scroll')
            } else {
                navbarPrimaryEl.classList.remove('scroll')
            }
        })
    }
}

function setMinHeight() {
    const updateMinHeight = (target) => {
        const footerHeight = document.querySelector('.footer').clientHeight;
        const windowHeight = window.innerHeight;
        target.style.minHeight = (windowHeight - footerHeight) + 'px';
    }

    const target = document.querySelector('.error') || document.querySelector('.success');
    if (target) {
        updateMinHeight(target);
        window.addEventListener('resize', () => updateMinHeight(target));
    }
}

document.addEventListener('DOMContentLoaded', () => {
    initAos()
    checkNavbarScrolled()
    setMinHeight()
})