import "../layout"

// library
import Swiper from 'swiper'
import { EffectFade, Autoplay } from 'swiper/modules'

function heroSwiper() {
    const swiper = new Swiper('.hero__swiper', {
        modules: [EffectFade, Autoplay],
        slidesPerView: 1,
        effect: 'fade',
        speed: 1000,
        loop: true,
        autoplay: {
            delay: 5000,
        },
    });
}

document.addEventListener('DOMContentLoaded', () => {
    heroSwiper()
})